<template>
  <div class="echarts">
    <!-- 缩略图 -->
    <div>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-card @click="goDetail(1,'受载期时间段')" class="itemCard"><div id="laycanData" class="echartCard"></div><span class="cardTitle">受载期时间段</span></el-card>
        </el-col>
        <el-col :span="6">
          <el-card @click="goDetail(2,'装卸船港口')" class="itemCard"><div id="port" class="echartCard"></div><span class="cardTitle">装卸船港口</span></el-card>
        </el-col>
        <el-col :span="6">
          <el-card @click="goDetail(3,'货物类型')" class="itemCard"><div id="cargo" class="echartCard"></div><span class="cardTitle">货物类型</span></el-card>
        </el-col>

      </el-row>
    </div>
    <!-- 详细大图 -->
    <el-dialog v-model="dialogTableVisible" :title="dialogTitle" @close="loading = true" fullscreen="true">
      <div v-loading="loading">
        <el-row>
          <el-col :span="18"><div id="detailChart" style="height: 500px;"></div></el-col>
          <el-col :span="6">
            <div >
              <el-date-picker v-model="datePickerData" type="daterange" size="small" unlink-panels value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始月份" end-placeholder="结束月份" :shortcuts="pickerOptions" @change="updateOpenTimeChart"> </el-date-picker>
              <div style="margin-top: 20px">
                <!-- <b>分组：&nbsp&nbsp&nbsp</b> -->
                <el-radio-group v-model="period" size="small" @change="changePeriod()" :disabled="periodDisabled">
                  <el-radio-button label="自动"/>
                  <el-radio-button label="按年"/>
                  <el-radio-button label="按月"/>
                  <el-radio-button label="按日"/>
                </el-radio-group>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {queryCargo} from '../../api/cargo'
import * as echarts from 'echarts';



const shortcuts = [{ text: '本月',
  value: [new Date(new Date().getFullYear(),new Date().getMonth(),1).getTime(), new Date().getTime()],
},
  {
    text: '今年',
    value: [new Date(new Date().getFullYear(), 0).getTime(),new Date().getTime()]
  },
  {
    text: '近六个月',
    value: [new Date((new Date()).getMonth < 6 ? new Date().getFullYear() - 1 : new Date().getFullYear(),new Date().getMonth() - 6).getTime(),new Date().getTime()]
  },
]


export default {

  data(){
    return{
      periodDisabled:true,
      period: '自动',
      loading: true, // 对话框加载
      dialogTableVisible: false, // 显示大图
      dialogTitle: '', //大图题目
      currentChart: '', // 当前对话框显示的图表
      // OpenTiemYearData: '2020', //默认2020年的数据
      pickerOptions: shortcuts,
      datePickerData: ['2021-01-01','2021-12-31'],
      openTimePeriod: ['2021-01-01','2021-12-31'],
      openTimeDisabled: false,
      // 受载期时间段图表变量
      laycanChart: {},
      laycanData: {},
      laycanDataAxis: [],
      laycanDataData: [],
      // 货物类型图表变量
      cargoChart: {},
      cargoData: {},
      cargoDataAxis: [],
      cargoDataData: [],
      // 装卸港时间段图表变量
      portChart: {},
      portData: {},
      portDataAxis: [],
      portDataData: [],
      noportDataData: [],
      params:{
        auto:false,
        laycanStartDataAnalysis:'2021-01-01',
        laycanEndDataAnalysis:"2021-12-31",
        laycanTimeYearDataAnalysis:false,
        laycanTimeMonthDataAnalysis:true,
        laycanTimeDayDataAnalysis:false,
        laycanAnalysis:true
      },
      paramsCargo:{
        cargoTypeAnalysis:true,//卸货港口英文名分析
        cargoType:'',//货物类型
        auto:true
      },
      paramsPort:{
        dischargeloadingPortEnameAnalysis:true,//装卸货港口英文名分析
        dischargePortEname:"",//卸货港
        loadingPortEname:'',//装货港
      },
      noDataOption:{
        title:{
          text: '暂无数据',
          x: 'center',
          y: 'center',
          textStyle: {
            color: '#65ABE7',
            fontWeight: 'normal',
            fontSize: 16
          }
        },
        xAxis:{
          data: []
        },
        yAxis:{
          name: ''
        },
        series: []
      },
      detailChart:{},
      main: Object,
      obj: Object,
      echarts: {},
    }
  },
  methods:{
    initLaycan(that,myChart){
      if(that.laycanDataData.length == 0){
        that.laycanData = that.noDataOption;
        return ;
      }
      that.laycanData = {
        xAxis: {
          data: that.laycanDataAxis,
          axisLabel: false,
          axisTick: {
            alignWithLabel: true
          },
          axisLine: {
            show: false
          },
          z: 10
        },
        yAxis: {
          name:'个数',
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: false
        },
        dataZoom: [
          {
            type: 'inside'
          }
        ],
        series: [
          {
            type: 'bar',
            showBackground: true,
            name: '个数',
            data: that.laycanDataData,
            barWidth: '60%',
            label: false,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#83bff6' },
                { offset: 0.5, color: '#188df0' },
                { offset: 1, color: '#188df0' }
              ])
            },
          },
        ]
      };
    },
    initPort(that,myChart){
      if(that.portDataData.length == 0){
        that.portData = that.noDataOption;
        return ;
      }
      that.portData = {
        legend: {},
        xAxis: {
          type: 'value',
          // boundaryGap: [0, 0.01]
          axisLabel: false
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        yAxis: {
          type: 'category',
          data: this.portDataAxis,
          axisLabel: false
        },
        series: [
          {
            type: 'bar',
            data:  this.noportDataData,
          },
          {
            name: '卸船港口-装船港口',
            type: 'bar',
            data: this.portDataData
          }
        ]
      };
    },
    initCargo(that,myChart){
      if(that.cargoDataData.length == 0){
        that.cargoData = that.noDataOption;
        return ;
      }
      that.cargoData = {
        legend: {},
        xAxis: {
          type: 'value',
          axisLabel: false
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        yAxis: {
          type: 'category',
          data: this.cargoDataAxis,
          axisLabel: false
        },
        series: [
          {
            name: '货物类型',
            type: 'bar',
            data: this.cargoDataData,
          },
        ]
      };
    },
    updateOpenTimeChart(){
        this.params.laycanTimeDataAnalysis=true
        // 设置参数
        this.openTimePeriod[0] = this.datePickerData[0].getFullYear() + '-' + (this.datePickerData[0].getMonth() + 1) + '-' + this.datePickerData[0].getDate();
        this.openTimePeriod[1] = this.datePickerData[1].getFullYear() + '-' + (this.datePickerData[1].getMonth() + 1) + '-' + this.datePickerData[1].getDate();
        this.params.laycanStartDataAnalysis = this.openTimePeriod[0];
        this.params.laycanEndDataAnalysis = this.openTimePeriod[1];
          /* this.params.startDateAnalysis = this.openTimePeriod[0];
        this.params.endDateAnalysis = this.openTimePeriod[1];
        this.paramsSeaarea.startDateAnalysis = this.openTimePeriod[0];
        this.paramsSeaarea.endDateAnalysis = this.openTimePeriod[1];
        this.paramsShipType.startDateAnalysis = this.openTimePeriod[0];
        this.paramsShipType.endDateAnalysis = this.openTimePeriod[1];
        this.paramsDwt.startDateAnalysis = this.openTimePeriod[0];
        this.paramsDwt.endDateAnalysis = this.openTimePeriod[1];*/

      /*  // 请求后端数据
      switch(this.currentChart){
        case 1:
          this.queryLaycan();
          break;
        case 2:
          this.queryPort();
          break;
        case 3:
          this.queryCargo();
          break;*/
      queryCargo(this.params).then(response => {
        this.data = response.datas;
        this.laycanDataAxis=[]
        this.laycanDataData=[]
        // 设置各个值
        for(let i = 0;i < this.data.length;i++){
          let x = this.data[i].laycanTimeYear == undefined ? (this.data[i].laycanTimeMonth == undefined ? this.data[i].laycanTimeDay : this.data[i].laycanTimeMonth) : this.data[i].laycanTimeYear;
          this.laycanDataAxis[i] = x;
          this.laycanDataData[i] = this.data[i].num;
        }
        // 初始化图表
        this.initLaycan(this,this.laycanChart);
        this.laycanChart.setOption(this.laycanData);
        var temp = {xAxis:{data: this.laycanDataAxis},series:[{data: this.laycanDataData}]}
        this.detailChart.setOption(temp);
      })
    },
    changePeriod(){
      if(this.period == '按年'){
        this.changePeriodParams(false,true,false,false);
      } else if(this.period == '按月'){
        this.changePeriodParams(false,false,true,false);
      } else if(this.period == '按日'){
        this.changePeriodParams(false,false,false,true);
      } else {
        this.changePeriodParams(true,false,false,false);
      }
        queryCargo(this.params).then(response => {
          this.data = response.datas;
          this.laycanDataAxis=[]
          this.laycanDataData=[]
          // 设置各个值
          for(let i = 0;i < this.data.length;i++){
            let x = this.data[i].laycanTimeYear == undefined ? (this.data[i].laycanTimeMonth == undefined ? this.data[i].laycanTimeDay : this.data[i].laycanTimeMonth) : this.data[i].laycanTimeYear;
            this.laycanDataAxis[i] = x;
            this.laycanDataData[i] = this.data[i].num;
          }
          // 初始化图表
          this.initLaycan(this,this.laycanChart);
          this.laycanChart.setOption(this.laycanData);
          var temp = {xAxis:{data: this.laycanDataAxis},series:[{data: this.laycanDataData}]}
          this.detailChart.setOption(temp);
        })
      },
    changePeriodParams(a,y,m,d){
      this.params.auto = a;
      this.params.laycanTimeYearDataAnalysis = y;
      this.params.laycanTimeMonthDataAnalysis = m;
      this.params.laycanTimeDayDataAnalysis = d;
    },
    goDetail(index,title){
      this.dialogTableVisible = true;
      this.dialogTitle = title;
      this.currentChart = index;
      if(index == 1){
        this.periodDisabled = false;
      } else {
        this.periodDisabled = true;
      }
      setTimeout(()=>{
        this.initDetailChart(index)
        this.loading = false
      },500)
    },
    closeDialog(){
      this.loading = true
      this.detailChart.dispose();
    },
    initDetailChart(index){
      var echarts = require('echarts')
      var chartElement = document.getElementById('detailChart')
      // charts.dispose();
      this.detailChart = echarts.init(chartElement)
      switch(index){
        case 1:
          var temp = this.laycanData;
          // 设置详细图标的格式
          temp.xAxis.axisLabel = {inside: false,color: '#999',rotate: 30}
          temp.yAxis.axisLabel = {color: '#999'}
          temp.series[0].label = {show: true,position:'top'}
          this.detailChart.setOption(temp,true);
          break;
        case 2:
          var temp = this.portData;
          temp.xAxis.axisLabel = {inside: false,color: '#999',rotate: 30}
          temp.yAxis.axisLabel = {color: '#999'}
          temp.series[0].label = {show: true,position:'right'}
          temp.series[1].label = {show: true,position:'right'}
          temp.series[0].barGap = 0;
          this.detailChart.setOption(this.portData,true);
          break;
        case 3:
          var temp = this.cargoData;
          temp.xAxis.axisLabel = {inside: false,color: '#999',rotate: 30}
          temp.yAxis.axisLabel = {color: '#999'}
          temp.series[0].label = {show: true,position:'right'}
          temp.series[0].barGap = 0;
          this.detailChart.setOption(this.cargoData,true);
          break;
      }
    },
    queryLaycan(){
      queryCargo(this.params).then(response => {
        this.data = response.datas;
        this.laycanDataAxis=[]
        this.laycanDataData=[]
          // 设置各个值
          for(let i = 0;i < this.data.length;i++){
            let x = this.data[i].laycanTimeYear == undefined ? (this.data[i].laycanTimeMonth == undefined ? this.data[i].laycanTimeDay : this.data[i].laycanTimeMonth) : this.data[i].laycanTimeYear;
            this.laycanDataAxis[i] = x;
            this.laycanDataData[i] = this.data[i].num;
          }
          // 初始化图表
          this.initLaycan(this,this.laycanChart);
          this.laycanChart.setOption(this.laycanData);
      })
    },
    queryPort(){
      queryCargo(this.paramsPort).then(response => {
       // console.log(response)
        if(response){
          // 设置各个值
          this.portDataAxis = [];
          this.portDataData = [];
          for(let i = 0;i < response.datas.length;i++){
            let result = response.datas[i].dischargePortEname+'-'+response.datas[i].loadingPortEname
            this.portDataAxis.push(result)
            this.portDataData.push(response.datas[i].num)
          }
          // 初始化图表
          this.initPort(this,this.portChart)
          this.portChart.setOption(this.portData);
        }
      })
    },
    queryCargo(){
      queryCargo(this.paramsCargo).then(response => {
        // 设置各个值
        this.cargoDataData = [];
        this.cargoDataAxis = [];
        for(let i = 0;i < response.datas.length;i++){
          this.cargoDataAxis.push(response.datas[i].cargoType)
          this.cargoDataData.push(response.datas[i].num)
        }
        // 初始化图表
        this.initCargo(this,this.cargoChart)
        this.cargoChart.setOption(this.cargoData);
      })
    },
  },
  mounted(){
    var echarts = require('echarts')
    var that = this;
    this.laycanChart = echarts.init(document.getElementById('laycanData'))
    this.portChart = echarts.init(document.getElementById('port'))
    this.cargoChart = echarts.init(document.getElementById('cargo'))
    this.queryLaycan();
    this.queryPort();
    this.queryCargo();
  }
}
</script>

<style lang="scss">
html, body {
  height: 100%;
}

body, ul, li, a, p, div {
  /*慎删*/
  padding: 0px;
  margin: 0px;
}
.itemCard{
  cursor:pointer;
  margin-bottom: 10px;
}
.echartCard{
  height: 200px;
  width: 100%;
}
.cardTitle{
  font-weight: 700;
  text-align: center;
}
</style>
