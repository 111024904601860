import request from '/src/utils/request'
//addtonnage里每个函数的意思需要问一下
export function queryCargo(param){
  return request({
    url: `/api/cargo/getAnalysis`,
    method: 'post',
    data: param
  })
}
export function getEmailDetail(param) {
  return request({
    url: `/cargo/cargo/getEmailDetail`,
    method: 'get',
    data: param
  })
}
export function matchCargo(param) {
  return request({
    url: `/cargo/cargo/matchCargo`,
    method: 'get',
    data: param
  })
}
export function searchmatchCargo(param) {
  return request({
    url: `/cargo/cargo/matchCargo`,
    method: 'post',
    data: param
  })
}
export function collectTonnageCargo(param) {
  return request({
    url: `/cargo/cargo/collectTonnageCargo`,
    method: 'get',
    data: param
  })
}
export function cancelCollectTonnageCargo(param) {
  return request({
    url: `/cargo/cargo/cancelCollectTonnageCargo`,
    method: 'get',
    data: param
  })
}
export function updateCargo(param) {
  return request({
    url: `/api/cargo/save`,
    method: 'post',
    data: param
  })
}
export function deleteCargo(param) {
  return request({
    url: `/api/cargo/delete`,
    method: 'post',
    data:param
  })
}

export function searchCargo(param) {
  return request({
    url: `/api/cargo/getcard`,
    method: 'post',
    data: param
  })
}

export  function searchUserCargo(param) {
  return request({
    url: `/api/cargo/getmycard`,
    method: 'post',
    data: param
  })
}
export  function searchCargopayed(param) {
  return request({
    url:`/api/cargo/getpaydetil`,
    method:'post',
    data:param
  })
}

export function searchCollCargo(param) {
  return request({
    url: `/api/cargo/selectCargo/getcard`,
    method: 'post',
    data: param
  })
}

//根据tonnageid查找tonnage信息
export  function searchCargobyId(param) {
 // console.log(param)
  return request({
    url:`/api/cargo/getdetil/${param}`,
    method:'post'
  })
}
export function collectcargo(param) {
  //console.log(param)
  return request({
    url: `/api/cargo/selectCargo/save`,
    method: 'post',
    data: param
  })
}
export function cancelCollectcargo(param) {
  return request({
    url: `/api/cargo/selectCargo/delete`,
    method: 'post',
    data:param
  })
}
export function addCargo(param) {
  return request({
    url: `/api/cargo/save`,
    method: 'post',
    data: param
  })
}
export function addCargoName(param) {
  return request({
    url: `/api/measserver/cargoName/save`,
    method: 'post',
    data: param
  })
}
//文件列表
export function getFilelist(param) {
  return request({
    url: `/api/cargo/accessory/getListById`,
    method: 'post',
    data:param
  })
}
//删除文件
export function deletecargoDocument(params) {
  return request({
    url: `/api/cargo/accessory/userDeleteFile`,
    method: 'post',
    data:params
  })
}
//图片下载
export function downloadcargoDocument(params) {
  return request({
    url: `/api/cargo/accessory/downloadFile`,
    method: 'post',
    data:params,
    responseType: "arraybuffer"
  })
}
//pdf下载
export function downloadcargoPdf(params) {
  return request({
    url: `/api/cargo/accessory/downloadFile`,
    method: 'post',
    data:params,
    responseType: "blob"
  })
}
